<template>
  <v-app theme="light">
    <v-app-bar app :absolute="isIndex" flat :height="$vuetify.display.mdAndUp ? 180 : 80">
      <template v-slot:prepend>
        <router-link to="/">
          <v-img height="auto" :width="$vuetify.display.mdAndUp ? 120 : 70"
            src="./assets/LRG.logo_120.png" alt="Life Reversal Gamimg.">
            <template #sources>
              <source srcset="./assets/LRG.logo_120.avif">
            </template>
          </v-img>
        </router-link>
      </template>
      <v-spacer></v-spacer>
      <template v-if="mdAndUp">
        <template v-for="item in menuItems" :key="item.title">
          <v-btn class="mr-4" :to="item.route" :variant="(item.variant as 'text' | 'outlined')"
            :text="item.title"></v-btn>
        </template>
      </template>
      <!-- md 未満の場合に表示するハンバーガーメニュー" -->
      <v-app-bar-nav-icon v-else @click="drawer = !drawer" :aria-label="drawer ? 'メニューを閉じる' : 'メニューを開く'"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app location="top" :absolute="isIndex"
      disable-resize-watcher>
      <v-list aria-label="ナビゲーションメニュー">
        <v-list-item v-for="item in menuItems" :key="item.title" @click="closeDrawer"
          :to="item.route" :prepend-icon="item.icon" role="option">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main :class="{ 'pt-0': isIndex }">
      <router-view />
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script lang="ts" setup>
import Footer from "./components/Footer.vue";
import { computed, ref, watch } from "vue";
import { useDisplay } from 'vuetify';
import { useRoute } from 'vue-router';
import { mdiBriefcaseOutline, mdiAccountGroupOutline, mdiNewspaperVariantOutline, mdiEmailOutline } from '@mdi/js';
import { useHead } from '@unhead/vue'

const { mdAndUp } = useDisplay();
const route = useRoute()

const defaultDescription = "株式会社Life Reversal Gamingは、ゲームを通じて多様な人々と繋がり、ゲームの未知なる可能性を見出し、社会にとってプラスとなる新たな価値創りに挑戦します。ゲーム要素を活用した課題解決事業、コミュニティ形成、教育・研修、クリエイティブサポート、イベント支援など、多彩なサービスを提供しています。";
const meta = computed(() => {
  return {
    title: `${route.meta.title ? route.meta.title + ' | ' : ''}Life Reversal Gaming.`,
    description: `${route.meta.description || defaultDescription}`,
  }
})

useHead({
  title: computed(() => meta.value.title),
  meta: [
    {
    name: "description",
    content: computed(() => meta.value.description),
  }
]
});

const drawer = ref(false);


const isIndex = computed(() => route.path === '/');

const menuItems = ref([
  { title: 'SERVICE', icon: mdiBriefcaseOutline, route: '/service', variant: 'text' },
  { title: 'COMPANY', icon: mdiAccountGroupOutline, route: '/company', variant: 'text' },
  { title: 'NEWS', icon: mdiNewspaperVariantOutline, route: '/news', variant: 'text' },
  { title: 'CONTACT', icon: mdiEmailOutline, route: '/contact', variant: 'outlined' }
]);

function closeDrawer() {
  drawer.value = false;
}

function scroll_control(event: any) {
  event.preventDefault();
}

watch(drawer, () => {
  if (drawer.value) {
    document.addEventListener("mousewheel", scroll_control, {
      passive: false,
    });
    document.addEventListener("touchmove", scroll_control, {
      passive: false,
    });
  } else {
    document.removeEventListener("mousewheel", scroll_control, false);
    document.removeEventListener("touchmove", scroll_control, false);
  }
})

</script>

<style></style>