<template>
  <v-container fluid class="about-section py-16">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8">
        <v-card class="about-card mx-auto" elevation="2">
          <v-card-text>
            <SectionTitle en="What is" ja="eスポGOMIとは" />
            <v-container class="pt-0 pb-12">
              <v-row>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="10" md="8">
                  <v-img src="../../assets/espogomi_logo.png"
                    alt="eスポGOMIロゴ eスポーツで社会課題を攻略!">
                    <template #sources>
                      <source type="image/avif" srcset="../../assets/espogomi_logo_xs.avif 600w, ../../assets/espogomi_logo.avif">
                    </template>
                  </v-img>
                  <p class="text-center mt-4 text-caption">ロゴは <a
                      href="https://saraparajp.wixsite.com/labosarah">LABO
                      SARAH</a> の大辻紗羅様にデザイン頂きました。</p>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
            <p class="text-body-1 mb-6">
              eスポGOMIは、コンピューターゲームによる競技型スポーツ「<span
                class="text-primary font-weight-bold">eスポーツ</span>」と、
              ごみ拾い活動にスポーツのエッセンスを加え今までの社会奉仕活動を「競技」へと
              変換させた日本発祥の全く新しいスポーツ「<span
                class="text-blue font-weight-bold">スポGOMI</span>」を組み合わせた革新的な取り組みです。
            </p>
            <v-row class="features mb-6">
              <v-col v-for="(feature, index) in features" :key="index" cols="12" sm="4">
                <v-hover v-slot="{ isHovering, props }">
                  <v-card v-bind="props" :elevation="isHovering ? 8 : 2"
                    :class="{ 'on-hover': isHovering }"
                    class="feature-card d-flex flex-column align-center pa-4" outlined>
                    <v-icon :icon="feature.icon" size="36" color="primary" class="mb-2"></v-icon>
                    <h3 class="text-h6 text-center mb-2">{{ feature.title }}</h3>
                    <p class="text-body-2 text-center">{{ feature.description }}</p>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <h3 class="text-h5 mb-4 secondary--text">運営団体</h3>
            <p class="text-body-1 mb-4">
              eスポGOMIは、以下の3つの団体で組織される<strong>eスポGOMI開催委員会</strong>によって運営されています。
            </p>
            <v-row align="center" justify="center" class="mb-6">
              <v-col v-for="(org, index) in organizations" :key="index" cols="12" sm="4"
                class="text-center">
                <v-img :src="getImageUrl(org.logo)" :alt="org.name" contain height="100"
                  class="mb-2"></v-img>
                <v-chip tile>{{ org.name }}</v-chip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import { useDisplay } from 'vuetify';
import { mdiGamepadVariant, mdiRecycle, mdiHandshake } from '@mdi/js'

import SectionTitle from '../SectionTitle.vue';
import { PreLoadImage } from '../../utils/PreLoadImage';

const { xs } = useDisplay();
PreLoadImage(new URL(`../../assets/espogomi_logo${xs.value ? '_xs' : ''}.avif`, import.meta.url).href);

const getImageUrl = (logoname) => new URL(`../../assets/${logoname}.png`, import.meta.url).href;

const features = ref([
  {
    icon: mdiGamepadVariant,
    title: 'eスポーツ',
    description: 'コンピューターゲームによる競技型スポーツ'
  },
  {
    icon: mdiRecycle,
    title: 'スポGOMI',
    description: 'ごみ拾い活動をスポーツに変換'
  },
  {
    icon: mdiHandshake,
    title: '社会貢献',
    description: '楽しみながら環境保護に貢献'
  }
])

const organizations = ref([
  {
    name: '日本スポGOMI連盟',
    logo: 'supogomi_logo'
  },
  {
    name: 'Life Reversal Gaming.',
    logo: 'LRG.logo'
  },
  {
    name: '横濱OneMM',
    logo: 'OneMM_logo'
  }
])
</script>

<style scoped>
.about-section {
  background-color: #ECECEC;
}

.about-card {
  background-color: white;
}

.feature-card {
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.feature-card.on-hover {
  border-color: #C00000;
}

.feature-card .v-icon {
  transition: all 0.3s ease;
}

.feature-card.on-hover .v-icon {
  transform: scale(1.1);
}
</style>