<template>
  <v-container class="vision-section py-16">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8">
        <section-title en="VISION" ja="ビジョン" />
        <p class="mb-8">
          eスポGOMIは、単なるイベントを超えた<strong class="text-primary">「チームビルディングプログラム」</strong>です。
          イベントの前後を含む一連のプロセスを通じて、組織の結束力を高め、
          環境保全への意識を育みます。<strong class="font-wight-bold">楽しみながら社会貢献する</strong>ことで、
          持続可能な組織づくりと未来につながる活動を実現します。
        </p>
        <v-row class="vision-points">
          <v-col v-for="(point, index) in visionPoints" :key="index" cols="12" md="4">
            <v-hover v-slot="{ isHovering, props }">
              <v-card v-bind="props" :elevation="isHovering ? 8 : 2"
                :class="{ 'on-hover': isHovering }"
                class="vision-point-card d-flex flex-column align-center pa-6 h-100">
                <v-icon :icon="point.icon" size="48" :color="point.color" class="mb-4"></v-icon>
                <h3 class="text-h5 mb-4 text-center">{{ point.title }}</h3>
                <p class="text-body-2 text-center">{{ point.description }}</p>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import { mdiAccountGroup, mdiEarth, mdiLightbulbOn } from '@mdi/js'
import SectionTitle from '../SectionTitle.vue'

const visionPoints = ref([
  {
    icon: mdiAccountGroup,
    title: "チームビルディング",
    description: "同じ楽しみを共有することで結束力を高め、組織の質を向上させます。",
    color: "primary"
  },
  {
    icon: mdiEarth,
    title: "未来への継承",
    description: "組織のリアルな繋がりを構築し、取り組みを通じて未来へ関係性を継承していきます。",
    color: "blue"
  },
  {
    icon: mdiLightbulbOn,
    title: "意識改革",
    description: "「楽しみながら」取り組むことの大切さをチームで実践し、自然に意識改革を促進します。",
    color: "yellow"
  }
])
</script>

<style scoped>
.vision-section {
  background-color: white;
}

.vision-card,
.vision-point-card {
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.vision-point-card.on-hover {
  border-color: #C00000;
}

.vision-point-card .v-icon {
  transition: all 0.3s ease;
}

.vision-point-card.on-hover .v-icon {
  transform: scale(1.1);
}
</style>