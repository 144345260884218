<template>
  <div class="w-100">
    <v-img src="../assets/office.webp" height="240" cover>
      <div class="h-100 w-100 bg-primary position-absolute"
        style="mix-blend-mode: multiply; opacity: 0.4;"></div>
      <SectionTitle en="Company" ja="会社案内" class="position-relative pt-4 pb-6" color="white">
      </SectionTitle>
      <div class="text-center text-h4 text-white position-relative font-weight-bold">
        創造的ソリューション<span class="text-h5 font-weight-bold">で</span><br>
        <span class="text-h5 font-weight-bold">あたらしい</span>価値を創出。
      </div>
    </v-img>
  </div>
  <v-container class="bg-white my-6">
    <SectionTitle en="Teams" ja="役員紹介" />
    <v-row>
      <v-spacer></v-spacer>
      <template v-for="p in exec" :key="p.name">
        <v-col cols="12" md="4">
          <v-dialog width="auto">
            <template v-slot:activator="{ props: activatorProps }">
              <ItimatsuShadow width="100%">
                <v-card v-bind="activatorProps" variant="outlined" class="bg-white h-100">
                  <v-img :src="`/img/${p.src}.jpg`"></v-img>
                  <v-card-item>
                    <v-card-title>{{ p.name }}</v-card-title>
                    <v-card-subtitle>{{ p.title }}</v-card-subtitle>
                    <template v-slot:append><v-icon color="primary"
                        :icon="mdiChevronRight"></v-icon></template>
                  </v-card-item>
                </v-card>
              </ItimatsuShadow>
            </template>
            <template v-slot:default="{ isActive }">
              <v-card max-width="500">
                <v-img :src="`/img/${p.src}.jpg`" max-height="300" cover position="top">
                  <div class="d-flex justify-end">
                    <v-btn :icon="mdiClose" variant="plain" class="ml-auto" color="white"
                      @click="isActive.value = false"></v-btn>
                  </div>
                </v-img>
                <v-card-item>
                  <v-card-title>{{ p.name }}</v-card-title>
                  <v-card-subtitle>{{ p.title }}</v-card-subtitle>
                </v-card-item>
                <v-card-text style="white-space: pre-wrap;">{{ p.text }}</v-card-text>
                <v-card-text>
                  <v-list>
                    <v-list-subheader class="text-h5 font-weight-bold">Record</v-list-subheader>
                    <v-list-item v-for="(item, i) in p.record" :key="i">
                      <template v-slot:prepend>
                        <v-avatar color="primary">
                          <v-icon :color="item.color" :icon="mdiTrophy"></v-icon>
                        </v-avatar>
                      </template>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-bold text-black">
                        {{ item.subtitle }}
                      </v-list-item-subtitle>
                      <template v-slot:append v-if="i === p.record.length - 1">
                        <span class="text-subtitle-1">{{ p.more_text }}</span>
                      </template>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-btn :icon="mdiYoutube" :href="p.youtube_link" target="_blank"
                    rel="noopener noreferrer"></v-btn>
                  <v-btn :icon="mdiTwitter" :href="p.twitter_link" target="_blank"
                    rel="noopener noreferrer"></v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text="Close Dialog" @click="isActive.value = false"></v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
        <v-spacer></v-spacer>
      </template>
    </v-row>
  </v-container>
  <About></About>
</template>

<script setup lang="ts">

import SectionTitle from '../components/SectionTitle.vue';
import ItimatsuShadow from '../components/ItimatsuShadow.vue';
import About from '../components/About.vue';
import { mdiChevronRight, mdiClose, mdiTrophy, mdiYoutube, mdiTwitter } from '@mdi/js';

const exec = [
  {
    name: "髙木光治 / KOUZI",
    title: "代表取締役",
    src: "kouzi",
    text: [
      "幼少期から兄とともに『マリオカート』シリーズをプレイし、ゲームの魅力に引き込まれる。",
      "大学卒業後、専門商社に就職。数年の勤務を経て、2020年に兄と共に株式会社Life Reversal Gamingを設立し、代表取締役に就任。",
      "日々の活動を通じて、多様な背景を持つ人々とコミュニケーションを促進し、社会的課題の解決を目指す。",
      "自身の経験を生かし、ゲームを通じた社会貢献と教育的利用に尽力。",
      "現在も兄と共にYouTubeチャンネル「THE TAKAGI BROTHERS」で毎週木曜日に生配信を行って、視聴者との交流を深めています。",
      "好きなものは、ゲーム、家族との時間、そして新しい挑戦を見つけること。",
    ].join("\n"),
    record: [
      { "title": "マリオカートアドバンス", "subtitle": "全国公式大会 準優勝2回、3位2回" },
      { "title": "大乱闘スマッシュブラザーズDX", "subtitle": "全国大会 inトイザラス 岡山予選 準優勝" },
      { "title": "マリオカートDS", "subtitle": "ジェンプフェスタ大会 優勝", color: "amber" },
      { "title": "マリオテニスオープン", "subtitle": "WHFʻ12 Summerチャンピオンシップベスト8" },
      { "title": "ウイニングイレブン2017", "subtitle": "RAGE 2on2オフライントーナメントベスト16" }
    ],
    more_text: "他",
    youtube_link: "https://www.youtube.com/channel/UCV5FYwa9soE38TIaHDmc1xg",
    twitter_link: "https://twitter.com/maskt_kt",
  },
  {
    name: "髙木伸夫 / NOBUO",
    title: "取締役",
    src: "nobuo",
    text: [
      "18歳の時に線維筋痛症を発症、一時期は寝たきりの生活を経験。",
      "今なお闘病中であるが、それにも負けずに『マリオカート』の世界大会で6回もの優勝経験を持つ。",
      "現在はゲームの教育的利用や社会貢献に力を入れており、ゲームを通じて世界中の人々と繋がることに情熱を注いでいる。",
      "さまざまな背景を持つ人々とコミュニケーションを促進し、社会的な課題解決に貢献することを目指しています。",
    ].join("\n"),
    record: [
      { "title": "マリオカートアドバンス", "subtitle": "全国公式大会 優勝4回", color: "amber" },
      { "title": "マリオカートダブルダッシュ!!", "subtitle": "全国公式大会 優勝4冠", color: "amber" },
      { "title": "マリオカートDS", "subtitle": "全国公式大会 優勝1回", color: "amber" },
      { "title": "マリオカートWii", "subtitle": "世界公式大会 優勝6回", color: "amber" },
      { "title": "ウイニングイレブン2017", "subtitle": "TGS2016トーナメント 優勝", color: "amber" }
    ],
    more_text: "他多数",
    youtube_link: "https://www.youtube.com/c/NOBUO1988",
    twitter_link: "https://twitter.com/gum_nt2"
  }
]

</script>
