<template>
  <div class="itimatsu-shadow" :style="`width:${props.width}`">
    <div class="itimatsu-shadow--shadow" :class="props.rounded ? 'itimatsu-shadow-rounded' : 'rounded'"></div>
    <div class="itimatsu-shadow--block" :class="props.hover ? 'itimatsu-shadow-hover' : ''">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  rounded: Boolean,
  hover: {
    type: Boolean,
    default: true
  },
  width: {
    type: String,
    default: 'auto'
  }
})
</script>

<style scoped>
.itimatsu-shadow {
  display: inline-block;
  position: relative;
  height: 100%;
}
.itimatsu-shadow--shadow {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #444444 25%, transparent 25%, transparent 75%, #444444 75%),
              linear-gradient(45deg, #444444 25%, transparent 25%, transparent 75%, #444444 75%);
              background-size: 6px 6px;
              background-position: 0 0, 3px 3px;
  background-color: #808080;
  position: absolute;
  top: 6px;
  left: 6px;
}
.itimatsu-shadow--block {
  position: relative;
  width: 100%;
  height: 100%;
}
.itimatsu-shadow--shadow + .itimatsu-shadow-hover.itimatsu-shadow--block:hover {
  top: 3px;
  left: 3px
}
.itimatsu-shadow-rounded {
  border-radius: 24px;
}
</style>