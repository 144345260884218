<template>
  <div class="w-100">
    <v-img src="../../assets/espogomi.png" height="240" cover alt="">
      <template #sources>
        <source type="image/avif"
          srcset="../../assets/espogomi_xs.avif 600w, ../../assets/espogomi.avif" sizes="100vw">
      </template>
      <div class="h-100 w-100 bg-primary position-absolute"
        style="mix-blend-mode: multiply; opacity: 0.4;"></div>
      <SectionTitle en="eスポGOMI" ja="eスポーツで社会課題を攻略！" class="position-relative mt-10" color="white">
      </SectionTitle>
    </v-img>
  </div>
</template>

<script setup>
import { useDisplay } from 'vuetify';
import SectionTitle from '../SectionTitle.vue';
import { PreLoadImage } from '../../utils/PreLoadImage';
const { xs } = useDisplay();
PreLoadImage(new URL(`../../assets/espogomi${xs.value ? '_xs' : ''}.avif`, import.meta.url ).href);
</script>

<style scoped></style>