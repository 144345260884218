<template>
  <hgroup class="section-title" :style="styles">
    <h3 class="text-h3 font-weight-bold">{{ en }}</h3>
    <p class="font-weight-bold">{{ ja }}</p>
  </hgroup>

</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  en: String,
  ja: String,
  color: {
    type: String,
    required: false,
    default: "#000000"
  }
})

const styles = computed(() => { return {'--bdc': props.color, 'color': props.color} } )

</script>

<style scoped>
h3 {
  border-bottom: 3px solid var(--bdc);
}
.section-title {
  padding-top: 48px;
  padding-bottom: 64px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
</style>