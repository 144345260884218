<template>
  <v-container class="h-screen position-relative">
    <div style="width: 80%" class="position-absolute top-0 bottom-0 left-0 right-0 mx-auto">
      <picture class="position-absolute top-0 bottom-0 left-0 right-0 w-100 h-100">
        <source type="image/avif" :srcset="avifSrcset" :sizes="sizes" />
        <img decoding="sync" loading="eager" :src="logoDefault" alt="Life Reversal Gaming." style="object-fit:contain" class="position-absolute top-0 bottom-0 left-0 right-0 w-100 h-100">
      </picture>
    </div>
    <img src="" alt="">
    <div class="scroll-indicator">
      SCROLL
      <div class="scroll-line mx-auto"></div>
    </div>
  </v-container>
  <v-container class="mt-16 position-relative" id="introduce">
    <v-img src="../assets/LRG.logo.mono.png" height="500" class="translucent">
      <template #sources>
        <source srcset="../assets/LRG.logo.mono.avif">
      </template>
      <v-row class="h-100 align-center">
        <v-col cols="12">
          <h3>株式会社Life Reversal Gaming.</h3>
          <p class="text-h6 mt-4 font-serif">
            ゲームを通じて多様な人々と繋がり、<br>
            <span class="text-h5 text-primary font-serif">ゲームの未知なる可能性</span>を見出し、<br>
            <span class="text-h5 text-primary font-serif">社会にとってプラス</span>となる<span
              class="text-h5 text-primary font-serif">新たなる価値創り</span>に挑戦します。
          </p>
        </v-col>
      </v-row>
    </v-img>
  </v-container>
  <v-container class="mt-16">
    <SectionTitle en="Business" ja="事業領域" />
    <p class="font-weight-bold text-h6 font-serif">わたしたちは、ゲームで感じるさまざまな“楽しさ”を<br>
      社会で生まれる課題と掛け合わせ、楽しく課題解決するサービスを提供しています。</p>
    <v-container>
      <v-img class="mx-auto my-8" width="50%" min-width="300" aspect-ratio="16 / 9"
        src="../assets/venn.svg" alt="Fun / Challenge"></v-img>
      <p class="text-body-1 mt-4 text-center">“楽しさ”と“課題”を掛け合わせることで、持続可能な事業をつくる</p>
    </v-container>
  </v-container>
  <div class="bg-gray py-12 mt-16">
    <v-container class="mt-6 pt-16">
      <SectionTitle en="Service" ja="サービス"></SectionTitle>
      <v-row>
        <v-col cols="12" md="6">
          <v-card variant="text" class="mb-8 font-serif">
            <p>わたしたちは、ゲームで感じるさまざまな“楽しさ”を 社会で生まれる課題と掛け合わせ、楽しく課題解決するサービスを提供しています。</p>
          </v-card>
          <template v-for="n in 2" :key="n">
            <v-lazy min-height="256" :options="{ threshold: 1.0 }" class="mb-8">
              <v-card class="h-100 d-flex flex-column border-sm border-opacity-100 mx-auto"
                max-width="400" variant="flat">
                <v-img :src="`/img/${service_list[n - 1].img}`" height="120px"
                  class="ma-4 position-relative" />
                <v-card-title class="headline">{{ service_list[n - 1].title }}</v-card-title>
                <v-card-text style="white-space: pre-wrap;">{{ service_list[n - 1].text
                  }}</v-card-text>
              </v-card>
            </v-lazy>
          </template>
        </v-col>
        <v-col col="12" md="6">
          <template v-for="n in 3" :key="n">
            <v-lazy min-height="256" :options="{ threshold: 1.0 }">
              <v-card class="h-100 d-flex flex-column border-sm border-opacity-100 mb-8 mx-auto"
                max-width="400" variant="flat">
                <v-img :src="`/img/${service_list[n + 1].img}`" height="120px"
                  class="ma-4 position-relative" />
                <v-card-title class="headline">{{ service_list[n + 1].title }}</v-card-title>
                <v-card-text style="white-space: pre-wrap;">{{ service_list[n + 1].text
                  }}</v-card-text>
              </v-card>
            </v-lazy>
          </template>
        </v-col>
      </v-row>
      <div class="my-12 text-center">
        <!-- <ItimatsuButton>詳細を見る</ItimatsuButton> -->
        <ItimatsuShadow rounded>
          <v-btn variant="flat" class="border-sm border-opacity-100 hv-primary" size="large" rounded
            to="service">詳細を見る</v-btn>
        </ItimatsuShadow>
      </div>
    </v-container>
  </div>
  <div class="bg-gray pt-2 pb-16">
    <v-container class="mt-6 mb-4 pt-16">
      <SectionTitle en="Company" ja="会社案内"></SectionTitle>
      <v-row>
        <v-col cols="12" md="5">
          <p class="text-h6">
            “ゲームがあったからこそ<br>ここに立てている、 生かされている。”
          </p>
          <p class="mt-4">
            ゲームは単なる娯楽ではない。人と人が繋がるコミュニケーションツールであり、時には人を勇気づけられるものだと確信しました。
          </p>
          <p>
            ゲームには、今は想像もできない、思いもよらない価値がある。私達は「人×ゲーム」の未知なる可能性を信じています。
          </p>
          <div class="my-12 text-center" v-if="$vuetify.display.mdAndUp">
            <ItimatsuShadow rounded>
              <v-btn variant="flat" class="border-sm border-opacity-100 hv-primary" size="large"
                rounded to="company">詳細を見る</v-btn>
            </ItimatsuShadow>
          </div>
        </v-col>
        <v-col cols="12" md="7">
          <video-player />
        </v-col>
      </v-row>
      <div class="my-12 text-center" v-if="!$vuetify.display.mdAndUp">
        <ItimatsuShadow rounded>
          <v-btn variant="flat" class="border-sm border-opacity-100 hv-primary" size="large" rounded
            to="company">詳細を見る</v-btn>
        </ItimatsuShadow>
      </div>
    </v-container>
    <Arrivals en="NEWS" ja="新着情報" :limit="5" more />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useDisplay } from 'vuetify';
import SectionTitle from '../components/SectionTitle.vue';
import ItimatsuShadow from '../components/ItimatsuShadow.vue';
import Arrivals from '../components/Arrivals.vue';
import VideoPlayer from '../components/VideoPlayer.vue';
import { PreLoadImage } from '../utils/PreLoadImage';
import logoDefault from '../assets/LRG.v.logo.png';
import logoXS from '../assets/LRG.v.logo_xs.avif';
import logoSM from '../assets/LRG.v.logo_sm.avif';
import logoMD from '../assets/LRG.v.logo_md.avif';
import logoLG from '../assets/LRG.v.logo_lg.avif';
import logoXL from '../assets/LRG.v.logo.avif';

const { xs, sm, md, lg } = useDisplay();


PreLoadImage( xs.value ? logoXS
            : sm.value ? logoSM
            : md.value ? logoMD
            : lg.value ? logoLG
            : logoXL);

const sizes = computed(() => {
  if (xs.value) return '100vw'
  if (sm.value) return '100vw'
  if (md.value) return '50vw'
  if (lg.value) return '33vw'
  return '25vw'
});

const avifSrcset = computed(() => `
  ${logoXS} 600w,
  ${logoSM} 960w,
  ${logoMD} 1264w,
  ${logoLG} 1904w,
  ${logoXL} 2400w
`);

const service_list = [
  {
    title: "課題解決事業",
    img: "mtg.svg",
    text: "社会・企業の課題に対しゲーム要素を取り入れ継続事業を創出するサービス"
  },
  {
    title: "コミュニティ事業",
    img: "srv.svg",
    text: "施設・事業所ごとにコミュニティの形成し情報管理・発信するサービス"
  },
  {
    title: "教育・研修事業",
    img: "sch.svg",
    text: "ゲームの“攻略”の考え方を教育機関・企業に提供するサービス"
  },
  {
    title: "クリエイティブ事業",
    img: "cre.svg",
    text: "動画の編集/サムネイル作成SNS運用を行うサービス"
  },
  {
    title: "イベントサポート事業",
    img: "737.png",
    text: "イベント出演から制作・開催・サポートまで提供するサービス"
  }
];



</script>

<style scoped>
.hv-primary:hover {
  color: rgb(var(--v-theme-primary));
}

.scroll-indicator {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #595959;
  font-size: 20px;
  font-weight: bold;
}

.scroll-line {
  width: 2px;
  height: 50px;
  background: linear-gradient(to bottom, #595959, #595959, transparent, #595959, #595959);
  animation: scrollLight linear 2s infinite;
  margin-top: 5px;
}

.scroll-line::before {
  content: '';
  width: 2px;
  height: 50px;
  background: #59595959;
  margin-top: 5px;
  display: block;
}

@keyframes scrollLight {
  0% {
    background-position: 0 0px;
  }

  /* 50% {
    background-position: 0 0;
  } */
  100% {
    background-position: 0 100px;
  }
}

.translucent::v-deep(.v-img__img) {
  opacity: 0.4;
}
</style>