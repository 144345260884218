import { createRouter, createWebHistory } from 'vue-router'
import index from '../pages/index.vue';
import service from '../pages/service.vue';
import company from '../pages/company.vue';
import contact from '../pages/contact.vue';
import espogomi from '../pages/espogomi.vue';
import news from '../pages/news.vue';
import notfound from '../pages/404.vue';

const routes = [
  {
    path: '/',
    name: 'index', component: index
  },
  {
    path: '/service',
    name: 'service',
    component: service,
    meta: {
      title: 'サービス',
      description: '楽しさを基盤に継続可能な事業を共創。課題解決事業を中心に、地方自治体、教育機関、法人との連携を通じて、eスポGOMIや目標設定ワークショップなどのコンテンツを提供し、各団体の課題解決を支援。事業創出やBPO化を通じて、未来の望む姿に近づけるモデルケースを提示します。',
    }
  },
  {
    path: '/company',
    name: 'company',
    component: company,
    meta: {
      title: '会社案内',
      description: '創造的ソリューションであたらしい価値を創出。ゲーム大会での実績とYouTube活動を通じてゲームの教育的利用や社会貢献を推進し、多様な背景を持つ人々とコミュニケーションを深め、社会的課題の解決に尽力しています。',
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
    meta: {
      title: 'お問い合わせ',
      description: '株式会社Life Reversal Gaming. へのお問い合わせはこのページからお送りください。',
    }
  },
  {
    path: '/service/espogomi',
    name: 'espogomi',
    component: espogomi,
    meta: {
      title: 'eスポGOMI',
      description: 'eスポGOMIは、コンピューターゲームによる競技型スポーツ「eスポーツ」と、 ごみ拾い活動にスポーツのエッセンスを加え今までの社会奉仕活動を「競技」へと 変換させた日本発祥の全く新しいスポーツ「スポGOMI」を組み合わせた革新的な取り組みです。',
    }
  },
  {
    path: '/news',
    name: 'news',
    component: news,
    meta: {
      title: '新着情報一覧',
      description: '株式会社Life Reversal Gamingの新着情報ページでは、最新のイベントや活動報告、メディア掲載情報などをお届けします。eスポGOMIや各種ワークショップ、コミュニティイベントなど、私たちの取り組みとその成果を随時更新しています。社会課題解決への取り組みや企業活動の最新情報をチェックしてください。',
    }
  },
  {
    path: '/news/:id',
    name: 'newsItem',
    component: news,
    meta: {
      title: '新着情報',
      description: '株式会社Life Reversal Gamingの新着情報ページでは、最新のイベントや活動報告、メディア掲載情報などをお届けします。eスポGOMIや各種ワークショップ、コミュニティイベントなど、私たちの取り組みとその成果を随時更新しています。社会課題解決への取り組みや企業活動の最新情報をチェックしてください。',
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: notfound,
    meta: {
      title: '404 not found',
      description: 'お探しのページが見つかりませんでした。URLが正しいかどうかを再度ご確認ください。株式会社Life Reversal Gamingのホームページに戻るか、メニューから目的のページをお探しください。',
    }
  },
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export default router
