<template>
  <div class="w-100">
    <v-img src="../assets/contact.webp" height="240" cover>
      <div class="h-100 w-100 bg-primary position-absolute"
        style="mix-blend-mode: multiply; opacity: 0.4;"></div>
      <SectionTitle en="Contact" ja="お問い合わせ" class="position-relative mt-10" color="white">
      </SectionTitle>
    </v-img>
  </div>
  <v-container class="my-16">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-overlay :model-value="isSubmit" class="align-center justify-center">
          <v-progress-circular color="primary" size="64" indeterminate></v-progress-circular>
        </v-overlay>
        <div v-if="status === 'success'" class="text-center my-12">
          <h3 class="text-h3 font-weight-bold text-primary">THANK YOU!
            <v-icon size="small" color="primary" style="margin-top: -8px;" :icon="mdiSendCheck"></v-icon>
          </h3>
          <h3 class="text-h5 mt-10">お問い合わせありがとうございます</h3>
          <p class="mt-2">お問い合わせを受け付けました。<br>後ほど担当者よりご連絡いたします。</p>
          <v-btn class="mt-4" color="primary" @click="$router.push('/')">ホームに戻る</v-btn>
        </div>
        <v-form ref="form" v-model="valid" @submit.prevent="submit" fast-fail v-else>
          <v-card variant="flat">
            <v-card-title class="text-h5">お問い合わせ</v-card-title>
            <v-card-text>
              <v-text-field v-model="name" :rules="[v => !!v || '名前は必須です']" label="名前 *"
                variant="outlined" class="mt-2" required></v-text-field>
              <v-text-field v-model="email" :rules="emailRules" label="メールアドレス *" variant="outlined"
                class="mt-2" required></v-text-field>
              <v-text-field v-model="phone" label="電話番号" variant="outlined"
                class="mt-2" persistent-hint></v-text-field>
              <v-textarea v-model="message" :rules="[v => !!v || '内容は必須です']" label="内容 *"
                class="mt-2" variant="outlined" required></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" type="submit" text="送信" block border></v-btn>
            </v-card-actions>
            <div v-if="status === 'error'" class="text-center my-4">
              <v-alert
                class="text-left"
                density="compact"
                title="送信に失敗しました"
                text="入力内容をご確認のうえ、後ほど再度お試しください。"
                color="primary"
                icon="$warning"
              ></v-alert>
            </div>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed, ref } from 'vue';
import { sendContact } from '../api/contact';
import { mdiSendCheck } from '@mdi/js';

const status = ref('')

const form = ref(null)
const valid = ref(false)
const name = ref('')
const email = ref('')
const phone = ref('')
const message = ref('')

const emailRules = [
  v => !!v || 'メールアドレスは必須です',
  v => /.+@.+\..+/.test(v) || '有効なメールアドレスを入力してください'
]

const isSubmit = computed(() => status.value === 'submit')
function submit() {
  if (form.value.validate() && form.value.isValid) {
    // フォームデータの送信処理
    status.value = 'submit';
    sendContact(
      JSON.stringify({
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value
      })
    )
    .then(data => {
      status.value = 'success';
      console.log('Success:', data);
    })
    .catch((error) => {
      status.value = 'error';
      console.error('Error:', error);
    });
  }
}
</script>

<style scoped></style>
