<template>
  <v-container fluid class="feedback-section py-16 my-16">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8">
        <section-title en="FEEDBACK" ja="参加者の声" />
        <v-row class="mb-6">
          <v-col v-for="(feedback, index) in feedbacks" :key="index" cols="12" md="4">
            <v-hover v-slot="{ isHovering, props }">
              <v-card v-bind="props" :elevation="isHovering ? 8 : 2"
                :class="{ 'on-hover': isHovering }" class="feedback-card h-100 d-flex flex-column">
                <v-card-text class="flex-grow-1 d-flex flex-column">
                  <p class="text-body-1 feedback-text mb-4 flex-grow-1">{{ feedback.comment }}</p>
                  <v-divider class="mb-4"></v-divider>
                  <div class="d-flex align-center">
                    <v-avatar color="primary" size="40" class="mr-4">
                      <v-icon :icon="feedback.icon" color="white"></v-icon>
                    </v-avatar>
                    <div>
                      <div class="text-subtitle-1 font-weight-bold">{{ feedback.name }}</div>
                      <div class="text-caption">{{ feedback.attribute }}</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import { mdiBriefcase, mdiHumanMaleChild, mdiAccountChild } from '@mdi/js'
import SectionTitle from '../SectionTitle.vue'

const feedbacks = ref([
  {
    name: "Aさん",
    attribute: "会社の同僚と参加 30代",
    comment: "ごみ拾いのみだと地域貢献的な意識が強く参加する方も限られますが、ゲームとして開催することで大人も子供もみんなで楽しく参加できました！みんなの笑顔がとても印象的でした。",
    icon: mdiBriefcase,
  },
  {
    name: "Bさん",
    attribute: "親子で参加 小学生",
    comment: "ゲームが楽しそうだったので参加しました！ ゲームは負けちゃったけど、ごみ拾いでポイントを稼げるようにたくさん拾いました！",
    icon: mdiHumanMaleChild,
  },
  {
    name: "Cさん",
    attribute: "親子で参加 40代",
    comment: "ごみ拾いだけでは参加を渋っていた息子が、ゲームもあると聞いて急にやる気になりました（笑）街にごみが散乱している事などを、家族で話し合う良いきっかけにもなりました。",
    icon: mdiAccountChild,
  }
])
</script>

<style scoped>
.feedback-section {
  background-color: #f5f5f5;
}

.feedback-card {
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.feedback-card.on-hover {
  border-color: #C00000;
}

.feedback-text {
  position: relative;
  font-style: italic;
}

.feedback-text::before,
.feedback-text::after {
  content: '"';
  font-size: 1.5em;
  color: #D24F49;
}
</style>