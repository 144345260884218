<template>
  <div class="w-100 bg-gray pb-16">
    <v-container class="mt-16">
      <SectionTitle en="About" ja="会社概要" />
      <v-card variant="flat">
        <v-card-text>
          <v-container>
            <v-row v-for="(detail, key) in companyDetails" :key="key" class="my-2">
              <v-col cols="4">
                <strong>{{ detail.title }}</strong>
              </v-col>
              <v-col cols="8">
                {{ detail.content }}
              </v-col>
              <v-divider v-if="key < companyDetails.length - 1"></v-divider>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import SectionTitle from '../components/SectionTitle.vue';

const companyDetails = reactive([
  { title: "会社名", content: "Life Reversal Gaming, Inc." },
  { title: "設立", content: "2020年11月" },
  { title: "所在地", content: "東京都文京区向丘1-16-16" },
  { title: "代表者", content: "髙木 光治" },
  { title: "電話番号", content: "090-3817-3457" },
  { title: "従業員数", content: "5名（業務委託メンバー含む）" },
  { title: "資本金", content: "300万円" }
])
</script>

<style scoped>
</style>
