<template>
  <v-footer class="darken-3 pa-0 flex-column" padless>
    <div class="w-100" v-if="!isContact">
      <v-img src="../assets/contact.webp" height="240" cover>
        <div
          class="h-100 w-100 bg-primary position-absolute"
          style="mix-blend-mode: multiply; opacity: 0.4;"
        ></div>
        <SectionTitle
          en="Contact"
          ja="お問い合わせ"
          class="position-relative pb-6"
          color="white"
        ></SectionTitle>
        <div class="text-center">
          <v-btn :append-icon="mdiChevronRight" to="/contact">コンタクトフォームへ</v-btn>
        </div>
      </v-img>
    </div>
    <div class="bg-primary w-100 py-5">
      <v-container>
        <v-row class="justify-center">
          <v-col cols="auto" class="d-flex align-end">
            <v-img src="../assets/LRG.logo.mono.png" height="30" width="30">
              <template #sources>
                <source srcset="../assets/LRG.logo.mono.avif">
              </template>
            </v-img>
            <p class="ml-2">株式会社Life Reversal Gaming.</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="my-5">
      <v-row justify="center" class="white--text">
        <v-col cols="12">
          <p class="text-center">
            ©2024 Life Reversal Gaming. All Rights Reserved.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import SectionTitle from './SectionTitle.vue';
import { computed } from 'vue';
import { mdiChevronRight } from '@mdi/js';
const route = useRoute();

const isContact = computed(() =>  route.path === '/contact' );
</script>
