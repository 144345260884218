<template>
  <div class="bg-gray py-2">
    <v-container class="bg-white my-16">
      <v-empty-state
      headline="404 Not Found."
      title="お探しのページは存在しません。"
      >
        <template v-slot:media>
          <v-img src="../assets/LRG.logo.png" height="200"></v-img>
        </template>
        <template v-slot:actions>
          <v-btn color="primary" @click="goHome">ホームに戻る</v-btn>
        </template>
      </v-empty-state>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()
const goHome = () => {
  router.push('/')
}
</script>