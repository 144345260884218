<template>
  <v-container fluid class="rules-section py-16 bg-gray">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8">
        <section-title en="RULES & FLOW" ja="ルールと流れ" />
        <v-card class="mb-2" elevation="2">
          <v-card-title class="text-h5 font-weight-bold">eスポGOMIの流れ</v-card-title>
          <v-card-text>
            <v-timeline align="start" side="end" hide-opposite>
              <v-timeline-item v-for="(step, index) in timeline" :key="index" dot-color="primary"
                size="large" :icon="step.icon">
                <template v-slot:opposite>
                  <div :class="`text-h6 ${step.color}--text`">{{ step.title }}</div>
                </template>
                <div class="w-100">
                  <v-card class="elevation-1 flex-shrink-0">
                    <v-card-title class="bg-primary mb-4">
                      {{ step.title }}
                    </v-card-title>
                    <v-card-text>
                      {{ step.description }}
                    </v-card-text>
                  </v-card>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="10" lg="8">
        <h3 class="text-h5 font-weight-bold mb-4"></h3>
        <v-card elevation="2">
          <v-card-title class="text-h5 font-weight-bold">eスポGOMIのルール</v-card-title>
          <v-list role="list">
            <v-list-item v-for="(rule, index) in rules" :key="index" class="mb-4" role="listitem">
              <template v-slot:prepend>
                <v-avatar color="primary" size="48">
                  <v-icon :icon="rule.icon" color="white"></v-icon>
                </v-avatar>
              </template>
              <v-list-item-title class="text-h6 mb-2 font-weight-bold">
                {{ index + 1 }}. {{ rule.title }}
              </v-list-item-title>
              {{ rule.description }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from 'vue'
import { mdiAccountGroup, mdiScale, mdiTrashCan, mdiTrophyAward, mdiRecycle, mdiNumeric1, mdiNumeric2, mdiNumeric3 } from '@mdi/js'
import SectionTitle from '../SectionTitle.vue'

const timeline = ref([
  { title: "ごみ拾い前半戦", description: "3〜4人のチームを組み、ごみを拾います。種類と重さによってポイントが加算されます。", icon: mdiNumeric1 },
  { title: "eスポーツ大会", description: "eスポーツ競技を行います。成績によって特典を獲得できます。", icon: mdiNumeric2 },
  { title: "ごみ拾い後半戦", description: "獲得した特典を活用しながら再度ごみ拾いを行い、最終的な成績を競います。", icon: mdiNumeric3 }
])

const rules = ref([
  {
    icon: mdiAccountGroup,
    title: "チーム編成",
    description: "参加者は3〜4人で1チームを組みます。チームで協力してごみ拾いとeスポーツに挑戦します。"
  },
  {
    icon: mdiRecycle,
    title: "2回のごみ拾い",
    description: "イベントは前半戦と後半戦の2回のごみ拾いセッションで構成されます。各セッションでチームはごみを集めてポイントを獲得します。"
  },
  {
    icon: mdiScale,
    title: "ポイント計算",
    description: "集めたごみは種類毎に重量を計り、種類に応じたごみの重量がポイントに換算されます。"
  },
  {
    icon: mdiTrashCan,
    title: "道具の使用",
    description: "各チームにはごみ拾い用のトングが1つ提供されます。これを効果的に使ってごみを集めましょう。"
  },
  {
    icon: mdiTrophyAward,
    title: "eスポーツのアドバンテージ",
    description: "中間のeスポーツ大会で優秀な成績を収めたチームは、後半のごみ拾いで有利になるアドバンテージを獲得できます。"
  }
])
</script>

<style scoped>
.rules-section {
  background-color: white;
}

.rule-card {
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.rule-card.on-hover {
  border-color: #C00000;
}

.rule-card .v-icon {
  transition: all 0.3s ease;
}

.rule-card.on-hover .v-icon {
  transform: scale(1.1);
}

.v-timeline--vertical.v-timeline.v-timeline--side-end .v-timeline-item ::v-deep(.v-timeline-item__body) {
  width: 100%;
}

.v-timeline--vertical.v-timeline--justify-auto {
  grid-template-columns: 0 min-content auto
}
</style>