/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import 'vuetify/styles';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';


// Composables
import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#C00000',
          secondary: '#D24F49',
          gray: '#ECECEC',
          white: '#FFFFFF',
          blue: '#316CB2',
          yellow: '#FFCA28',
          amber: 'FFC107',
        }
      }
    }
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})
