<template>
  <v-container class="py-0">
    <div class="bg-white py-2">
      <SectionTitle :en="props.en" :ja="props.ja" id="arrivalsTitle"></SectionTitle>
      <v-divider aria-hidden="true"></v-divider>
      <template v-if="paginatedItems.length > 0">
        <v-list two-line subheader aria-labelledby="arrivalsTitle" class="py-0">
          <template v-for="newsItem in paginatedItems" :key="newsItem.id">
            <v-list-item three-line @click="goToNewsItem(newsItem.id)" role="option">
              <v-list-item-title class="headline">{{ newsItem.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ newsItem.date }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ newsItem.summary }}</v-list-item-subtitle>
              <template v-slot:append>
                <v-list-item-action>
                  <v-icon small color="primary" :icon="mdiArrowRight"></v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider aria-hidden="true"></v-divider>
          </template>
        </v-list>
        <v-pagination v-model="currentPage" :length="totalPages" circle
          :size="mdAndUp ? 'default' : 'small'" :class="{ 'pt-1': !$vuetify.display.mdAndUp }"
          v-if="props.pagination"></v-pagination>
      </template>
      <template v-else>
        <v-list two-line subheader aria-labelledby="arrivalsTitle" class="py-0">
          <div v-for="i in itemsPerPage" :key="i">
            <v-skeleton-loader type="list-item" height="64"></v-skeleton-loader>
            <v-divider aria-hidden="true"></v-divider>
          </div>
        </v-list>
        <v-pagination :length="0" :size="mdAndUp ? 'default' : 'small'"
          v-if="props.pagination"></v-pagination>
      </template>
      <div class="my-12 text-center" v-if="more">
        <ItimatsuShadow rounded>
          <v-btn variant="flat" class="border-sm border-opacity-100 hv-primary" size="large" rounded
            to="/news">もっと見る</v-btn>
        </ItimatsuShadow>
      </div>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useDisplay } from 'vuetify';
import { useRouter } from 'vue-router';
import SectionTitle from "./SectionTitle.vue";
import { getNews } from '../api/news';
import { mdiArrowRight } from '@mdi/js';

const { mdAndUp } = useDisplay();

const props = defineProps({
  en: { type: String, required: true },
  ja: { type: String, required: true },
  tag: { type: String, required: false },
  more: { type: Boolean, required: false, default: false },
  pagination: { type: Boolean, required: false, default: false },
  limit: { type: Number, required: false }
})

interface news {
  id: string,
  title: string,
  date: string,
  summary: string,
}

let rawNewsItems = ref([] as news[]);
(async () => {
  rawNewsItems.value = await getNews(undefined, props.tag);
})();

const itemsPerPage: number = props.limit || 10

const router = useRouter()

function goToNewsItem(id: string) {
  router.push(`/news/${id}`);
}

const currentPage = ref(1);

const totalPages = computed(() => {
  return Math.ceil(!rawNewsItems.value ? 1 : rawNewsItems.value.length / itemsPerPage);
})

const paginatedItems = computed(() => {
  const start: number = (currentPage.value - 1) * itemsPerPage;
  const end: number = start + itemsPerPage;
  return !rawNewsItems.value ? [] : rawNewsItems.value.slice(start, end);
})

</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>
