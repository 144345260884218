<template>
  <div class="w-100">
    <v-img src="../assets/news.webp" height="240" cover>
      <div class="h-100 w-100 bg-primary position-absolute"
        style="mix-blend-mode: multiply; opacity: 0.4;"></div>
      <SectionTitle en="NEWS" ja="新着情報" class="position-relative mt-10" color="white">
      </SectionTitle>
    </v-img>
  </div>
  <Arrivals en="" ja="" class="mb-16" :limit="5" pagination v-if="isListPage"></Arrivals>
  <div class="w-100 py-16 bg-gray" v-else>
    <v-container class="bg-white">
      <v-row>
        <v-col class="my-16">
          <v-skeleton-loader v-if="state == 'loading'" type="heading,subtitle,text"></v-skeleton-loader>
          <v-empty-state v-if="state == 'none'" headline="Page Not Found"></v-empty-state>
          <div v-if="state == 'success'">
            <h1 class="text-h4 font-weight-bold">{{ news_list.title }}</h1>
            <p class="text-body-1 mt-1 mb-10">{{ formatDate(news_list.date) }}</p>
            <div style="line-height: 2em;">
              <p>{{ news_list.summary }}</p>
              <p>{{ news_list.contents }}</p>
              <p>
              <a :href="news_list.link" target="_blank" rel="noopener noreferrer">{{ news_list.link }}</a>
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <div class="mb-12 text-center">
            <ItimatsuShadow rounded>
              <v-btn
                variant="flat"
                class="border-sm border-opacity-100 hv-primary"
                size="large"
                rounded
                to="/news"
              >一覧へ</v-btn>
            </ItimatsuShadow>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import Arrivals from '../components/Arrivals.vue';
import { computed, ComputedRef, ref, watch } from 'vue';
import { getNews } from '../api/news';
import { useHead } from '@unhead/vue'

const route = useRoute();

let state = ref('loading');
let news_list = ref();

const id = ref(route.params.id);
const isListPage = computed(() => typeof id.value === 'undefined');

const title = computed(() => {
  if ( isListPage.value ) {
    return '新着情報一覧';
  }
  return state.value === 'success' ? news_list.value.title : '新着情報';
});
const description: ComputedRef<string> = computed(() => {
  return state.value === 'success' ? news_list.value.summary : route.meta.description;
})

useHead({
  title: computed(() => `${title.value} | Life Reversal Gaming.'`),
  meta: [{
    name: 'description',
    content: computed(() => description.value),
  }]
});



if (!isListPage.value) {
  if (isNaN(Number(id.value))) {
    state.value = 'none';
  } else {
    getArticle();
  }
}

watch(() => route.params.id, (newId) => {
  state.value = 'loading';
  id.value = newId;
  if (!isListPage.value) {
    if (isNaN(Number(id.value))) {
      state.value = 'none';
    } else {
      getArticle();
    }
  }
});

async function getArticle() {
  console.log(`[getArticle]  id: ${id.value}`)
  news_list.value = await getNews(Number(id.value));
  state.value = !news_list.value ? 'none' : 'success';
}

function formatDate(input: string): string {
  const dateParts = input.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  return `${year}年${month}月${day}日`;
}

</script>

<style scoped></style>
