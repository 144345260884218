<template>
  <div class="w-100">
    <v-img src="../assets/laptop.webp" height="240" cover>
      <div class="h-100 w-100 bg-primary position-absolute"
        style="mix-blend-mode: multiply; opacity: 0.4;"></div>
      <SectionTitle en="Service" ja="事業内容" class="position-relative pt-4 pb-6" color="white">
      </SectionTitle>
      <div class="text-center text-h4 text-white position-relative font-weight-bold">
        楽しさ<span class="text-h5 font-weight-bold">を基盤に<br>
        </span>継続可能<span class="text-h5 font-weight-bold">な事業を</span>共創。
      </div>
    </v-img>
  </div>
  <v-container class="bg-white">
    <SectionTitle en="Detail" ja="事業詳細" />
    <v-row>
      <v-col cols="12" md="4" class="text-center">
        <v-img src="/img/mtg.svg" max-width="300" class="mx-auto"></v-img>
        <h3 class="text-h4 border-b-lg border-primary border-opacity-100 d-inline-block">課題解決事業</h3>
      </v-col>
      <v-col>
        <p class="text-h6">
          2020年11月設立以降、メインとなるサービス。<br>
          コンテンツ開発を行なったのち、開発事業の運営に加え、拡大と継続に力を注ぐ。
        </p>
        <div class="my-5 width-100">
          <ItimatsuShadow :hover="false" width="100%">
            <v-card variant="outlined" class="bg-white">
              <v-card-title>連携団体</v-card-title>
              <v-card-text>
                <div class="d-flex flex-wrap font-weight-bold">
                  <div class="w-50">
                    <v-icon left :icon="mdiCheck"></v-icon>
                    地方・自治体
                  </div>
                  <div class="w-50">
                    <v-icon left :icon="mdiCheck"></v-icon>
                    教育機関
                  </div>
                  <div class="w-50">
                    <v-icon left: :icon="mdiCheck"></v-icon>
                    営利法人
                  </div>
                  <div class="w-50">
                    <v-icon left :icon="mdiCheck"></v-icon>
                    非営利法人
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </ItimatsuShadow>
        </div>
        <div class="my-5 width-100">
          <ItimatsuShadow :hover="false" width="100%">
            <v-card variant="outlined" class="bg-white">
              <v-card-title>取組事例<span class="text-body-2 ml-2">※一部抜粋</span></v-card-title>
              <v-card-text>
                <v-chip to="/service/eSpoGomi" class="ma-2" color="primary" text-color="white"
                  size="x-large" :append-icon="mdiArrowRight">
                  eスポGOMI
                </v-chip>
                <v-chip class="ma-2">
                  目標設定ワークショップ
                </v-chip>
                <br>
                <v-chip class="ma-2">
                  教育コンテンツ各種
                </v-chip>
                <v-chip class="ma-2">
                  オンラインコミュニティ
                </v-chip>
              </v-card-text>
            </v-card>
          </ItimatsuShadow>
        </div>
        <div class="my-5 width-100">
          <ItimatsuShadow :hover="false" width="100%">
            <v-card variant="outlined" class="bg-white" width="100%">
              <v-card-title>支援団体累計推移</v-card-title>
              <v-card-text style="height: 300px;">
                <BarChart></BarChart>
              </v-card-text>
            </v-card>
          </ItimatsuShadow>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <div class="bg-gray w-full pt-2 pb-16">
    <SectionTitle en="Strength" ja="課題解決事業の活用" />
    <v-container class="mb-16 pt-8 pb-16">
      <v-row>
        <v-col>
          <p class="text-h6 font-weight-bold">
            各団体の抱える課題を弊社のコンテンツを体験することで解決手段を見出し、<br>
            独自コンテンツへの反映、応用を行うことができる
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="12" md="5">
          <ItimatsuShadow width="100%" :hover="false">
            <v-card class="h-100 bg-white" variant="outlined">
              <v-card-title>現状の未来までの距離</v-card-title>
              <v-img src="../assets/strength_before.svg" width="90%" class="ma-auto"></v-img>
              <v-card-text class="text-center">
                <p class="mb-4">
                  現在抱えている課題に対して、<br>
                  未来の望む姿に向かうにあたり<br>
                  何らかの障壁を抱えている
                </p>
                <div class="text-left mx-auto" style="width: fit-content">
                  <p>
                    ・コンテンツ不足<br>
                    ・人員不足<br>
                    ・時間の不足<span class="ml-8">etc.</span>
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </ItimatsuShadow>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="5">
          <ItimatsuShadow width="100%" :hover="false">
            <v-card class="h-100 bg-white" variant="outlined">
              <v-card-title>弊社が介在する今後の姿</v-card-title>
              <v-img src="../assets/strength_after.svg" width="90%" class="ma-auto"></v-img>
              <v-card-text class="text-center">
                <p class="mb-4">
                  現在抱えている課題に対して、<br>
                  弊社が介在することで<br>
                  モデルケースを提示し未来に近づける
                </p>
                <div class="text-left mx-auto" style="width: fit-content">
                  <p>
                    ・コンテンツの提供<br>
                    ・事業創出<br>
                    ・事業のBPO化<span class="ml-8">etc.</span>
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </ItimatsuShadow>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import SectionTitle from '../components/SectionTitle.vue';
import ItimatsuShadow from '../components/ItimatsuShadow.vue';
import { mdiCheck, mdiArrowRight } from '@mdi/js';
import {defineAsyncComponent} from 'vue';
import { PreLoadImage } from '@/utils/PreLoadImage';
PreLoadImage(new URL('../assets/laptop.webp', import.meta.url).href);
const BarChart = defineAsyncComponent(() => import('../components/BarChart.vue'));
</script>