<template>
  <video ref="videoPlayer" width="100%" height="auto" :controls="visibleControls"
    @mouseover="showControls" @mouseleave="hideControls" poster="../assets/thumbnail.png"
    preload="none">
    <source src="../assets/interview.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useDisplay } from 'vuetify';
const visibleControls = ref(false);

const { mobile } = useDisplay();
visibleControls.value = mobile.value;

const showControls = () => {
  visibleControls.value = true;
}
const hideControls = () => {
  visibleControls.value = false;
}
</script>

<style scoped>
video {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
}
</style>