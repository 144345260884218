import axios from 'axios';
const url = 'https://life-reversal-gaming.hackof.net/api/contact.php'

export async function sendContact(body: string) {
  console.log("send contact");
  try {
    const response = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = response.data;
    console.log(`get data: ${JSON.stringify(data)}`);
    if (response.status !== 200) {
      throw new Error(data.message || 'Unknown error occurred');
    }
    return data;
  } catch (error) {
    console.error(`error: ${JSON.stringify(error)}`);
    throw error;
  }
}