// import news_list from './news_release.json' with { type: "json" };

import axios from 'axios';
interface NewsArticle {
  id: number,
  date: string,
  title: string,
  summary: string,
  contents: string,
  link: string,
  tag: string
}

const url = 'https://life-reversal-gaming.hackof.net/api/news.php'

const fetchData = async (id?:number, tag?: string) => {
  const param = (id !== undefined) ? `?id=${String(id)}` : (tag !== undefined) ? `?tag=${tag}` : '';
  try {
    const response = await axios.get(url + param);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export async function getNews(id?: number, tag?: string) {
  const news_list = await fetchData(id, tag);
  if (id != null) { return news_list; }
  const result_news_list = news_list.toSorted((a: NewsArticle, b: NewsArticle) => new Date(b.date).getTime() - new Date(a.date).getTime())
  return (!id) ? result_news_list : result_news_list.find((v: NewsArticle) => v.id === id);
}
